import React, {useState, useEffect} from 'react';
import {AdminUpdate} from "../components/adminUpdatePage/AdminUpdate";
import {authService} from "../service/auth/auth.service";
import {getStoreLocationService} from "../service/storeLocator/storeLocator.service";

const loginLink = process.env.REACT_APP_STS_REDIRECT_LOGIN_LINK;
const sessionStorageKey = process.env.REACT_APP_STS_SESSION_STORAGE_KEY;

function AdminUpdatePage(customProps) {
    if (sessionStorage.getItem(sessionStorageKey)) {
        sessionStorage.removeItem(sessionStorageKey);
    }
    const {alert, showAlert} = customProps;
    const [lookUpResponse, setLookUpResponse] = useState();
    const queryString = window.location.search;
    const [registering, setRegistering] = useState(false);
    const [lookingUp, setLookingUp] = useState(false);
    const [login, setLogin] = useState(false);
    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    });
    const [loginError, setLoginError] = useState({
        username: '',
        password: '',
        authorized: ''
    });
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNo: '',
        storeLocation: '',
        storeNumber: '',
        customerId: ''
    });
    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNo: '',
        storeLocation: '',
        customerId: ''

    });

    const [userName, setUserName] = useState();
    const [showSummary, setShowSummary] = useState(false);
    const handleSummaryClose = () => {
        setShowSummary(false);

    };
    const handleSummaryShow = () => {
        setShowSummary(true);
    }

    const [stores, setStores] = useState([]);
    const [encoded] = useState(localStorage.getItem('basicauth'));
    const [autoSet, setAutoSet] = useState(false);

    useEffect(() => {
        // getMyLocation();
        if (!stores.length) {
            getStoreLocationService.getStoreLocator()
                .then((data) => {
                    if (data) {
                        let stores = data.Stores;
                        stores = stores.filter((store) => {
                            if (store.ecomName) {
                                store.originalEcomName = store.ecomName;
                                return store;
                            }
                            return null;
                        })
                        setStores(stores);
                    }
                })
        }

    }, [stores.length]);

    useEffect(() => {

        if (encoded) {
            let decoded = atob(encoded);
            if (decoded) {
                decoded = decoded.split(":");
                setUserName(decoded.length > 0 ? decoded[0] : null);
            }
        }
    }, [encoded]);

    const handleChange = (event) => {
        setUser({...user, [event.target.name]: event.target.value});
    }

    const changeStoreLocation = (event) => {
        handleChange(event);
    }

    const reloadPage = (page) => {
        setTimeout(() => {
            if (page === 'login') {
                window.location.href = loginLink;
            } else {
                window.location.href = `/${page}`;
            }
        }, 4000)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validate()) {
            setRegistering(true);
            authService.updateProfile({...user, birthday:lookUpResponse?.data[0]?.birthday}).then(data => {
                    if (data && !data?.error) {
                        showAlert('AdminUpdate successful!', "success");
                        reloadPage('admin/update/profile');
                    } else {
                        showAlert(data?.error, "danger")
                    }
                    setRegistering(false);
                }, error => {
                    showAlert(error, "danger");
                }
            );
        }
    }
    const handleSetValues = (data) => {
        setAutoSet(true);
        if (!data) {
            showAlert("Data not available", "danger");
            return;
        }
        setUser({
            ...user,
            customerId: data?.id ?? "",
            firstName: data?.firstName ?? "",
            lastName: data?.lastName ?? "",
            phoneNo: data?.phoneNo ?? "",
            email: data?.email ?? "",
            storeNumber: data?.storeNumber ?? ""
        });
        setAutoSet(false);
    }


    const handleLookup = (event) => {
        event.preventDefault();
        if (validateLookUpField()) {
            setLookingUp(true);
            authService.lookUpAccount(lookupData).then(data => {
                    if (data && !data?.error) {
                        setLookUpResponse(JSON.parse(JSON.stringify(data)));
                        const wynshopResponse = data?.meta?.wynshopCustomer;
                        if (wynshopResponse) {
                            handleSetValues(wynshopResponse);
                        } else {
                            setUser({
                                ...user,
                                firstName: '',
                                lastName: '',
                                email: '',
                                phoneNo: '',
                                storeLocation: '',
                                storeNumber: '',
                                customerId: ''
                            });
                        }
                        showAlert('Look up successful!', "success");
                        handleSummaryShow();
                    } else {
                        setLookUpResponse(null);
                        showAlert(data?.error, "danger")
                    }
                    setLookingUp(false);
                }, error => {
                    setLookUpResponse(null);
                    setLookingUp(false);
                    showAlert("Service not available", "danger");
                }
            );
        }
    }
    const handleLogin = (event) => {

        event.preventDefault();
        if (validateCredentials()) {
            setLogin(true);

            authService.loginAdmin(credentials).then(data => {
                    if (data && !data?.error) {
                        localStorage.setItem("basicauth", btoa(credentials.username + ':' + credentials.password))
                        showAlert('Login successful! loaded lookup section...', "success");
                        setUserName(credentials.username);
                    } else {
                        setLoginError("Invalid username or password!!!");
                        showAlert("Invalid username or password!!!", "danger");
                    }
                    setLogin(false);
                }, error => {
                    showAlert("Service connection issue.", "danger")
                    setLogin(false);
                }
            );

        }

    }
    const [lookupError, setLookupError] = useState({
        searchValue: '',
        fieldType: ''

    });

    const [lookupData, setLookupData] = useState({
        searchValue: '',
        fieldType: ''

    });
    const validateLookUpField = () => {

        let input = lookupData;
        let errors = {};
        let isValid = true;

        if (!input["searchValue"]) {
            isValid = false;
            lookupError["searchValue"] = "Please enter Search Value.";
        }
        setLookupError(
            errors
        );
        return isValid;
    }

    const validateCredentials = () => {

        let input = credentials;
        let errors = {};
        let isValid = true;

        if (!input["username"]) {
            isValid = false;
            loginError["username"] = "Please enter valid username.";
        }
        if (!input["password"]) {
            isValid = false;
            loginError["password"] = "Please enter valid password.";
        }
        setLookupError(
            errors
        );
        return isValid;
    }


    const validate = () => {
        let pattern;
        let input = user;
        let errors = {};
        let isValid = true;

        if (!input["customerId"]) {
            isValid = false;
            errors["customerId"] = "Please enter Customer ID.";
        }

        if (!input["firstName"]) {
            isValid = false;
            errors["firstName"] = "Please enter your first name.";
        }
        if (!input["lastName"]) {
            isValid = false;
            errors["lastName"] = "Please enter your last name.";
        }

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }

        if (typeof input["email"] !== "undefined") {

            pattern = new RegExp(/^(("[\w-\s]+")|([+\w-]+(?:\.[+\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            // pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter a valid email address.";
            }
        }

        if (!input["storeLocation"]) {
            isValid = false;
            errors["storeLocation"] = "Please select store nearby.";
        }

        if (!input["phoneNo"]) {
            isValid = false;
            errors["phoneNo"] = "Please enter your phone number.";
        }


        if (typeof input["phoneNo"] !== "undefined") {
            pattern = new RegExp(/^[0-9\b]+$/);

            if (!pattern.test(input["phoneNo"])) {
                isValid = false;
                errors["phoneNo"] = "Please enter only numbers.";
            } else if (input["phoneNo"].length !== 10) {
                isValid = false;
                errors["phoneNo"] = "Please enter a valid phone number.";
            }
        }
        setError(
            errors
        );
        return isValid;
    }

    const props = {
        handleSubmit,
        handleChange,
        error,
        stores,
        queryString,
        changeStoreLocation,
        registering,
        alert,
        lookupData,
        setLookupData,
        lookupError,
        setLookupError,
        lookingUp,
        handleLookup,
        lookUpResponse,
        user,
        handleSetValues,
        showSummary,
        handleSummaryClose,
        autoSet,
        handleLogin,
        login,
        setCredentials,
        loginError,
        credentials,
        setUser,
        showAlert,
        setLookUpResponse,
        userName,
        setUserName
    };
    return <AdminUpdate {...props}/>
}

export default AdminUpdatePage;
